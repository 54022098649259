import React from 'react';
import styled from 'styled-components';
import { ICON_FONT_SIZE } from '../../helpers/defaultSizes';
import { Window } from '../desktopComponents/Window';

const GameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  font-size: ${ICON_FONT_SIZE};
`;

export const Game = () => {
  return (
    <Window title={'game'} width={'450px'} height={'100px'} bgColor={'white'}>
      <GameContainer>Game coming soon! ( ͡° ͜ʖ ͡°)</GameContainer>
    </Window>
  );
};
