import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  closeWindow,
  focusWindow,
  minimiseWindow,
} from '../../redux/ducks/windows';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import Draggable from 'react-draggable';

const StyledDraggable = styled.div`
  position: relative;
  z-index: ${(props) => props.zIndex};
`;

const StyledWindow = styled(motion.div)`
  position: absolute;
  overflow: hidden;
  scroll: none;
  width: ${(props) => (props.width ? props.width : '700px')};
  top: 200px;
  left: 300px;
  min-height: ${(props) => (props.height ? '0px' : '400px')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  ::-webkit-scrollbar {
    display: none;
  }
  &::focus {
    z-index: 100;
  }
  @media only screen and (min-width: 100px) and (max-width: 800px) {
    position: absolute;
    overflow: hidden;
    scroll: none;
    width: 100%;
    max-width: 700px;
    top: 150px;
    left: 0px;
    height: ${(props) => (props.height ? props.height : 'auto')};
    ::-webkit-scrollbar {
      display: none;
    }
    &::focus {
      z-index: 100;
    }
  }
`;

const StyledTitleBar = styled.div`
  button:hover {
    cursor: pointer;
  }
`;

const StyledWindowBody = styled.ul`
  height: calc(100% - 20px);
  overflow: auto;
  display: flex;

  justify-content: center;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor.concat(' !important') : '#c0c0c0 !important'};
  @media only screen and (min-width: 100px) and (max-width: 1200px) {
    height: calc(100% - 24px);
  }
`;

const StyledWindowContent = styled.div`
  height: 100%;
  width: 100%;
`;

export const Window = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });
  const showHideValue = useSelector((state) => state.showHideState.window);
  const focusedWindow = useSelector((state) => state.showHideState.focused);
  const minimised = useSelector((state) => state.showHideState.minimised);
  const [fullscreen, setFullscreen] = useState(false);

  console.log(focusedWindow);

  const [windowPosition, setWindowPosition] = useState({
    deltaPosition: {
      x: 0,
      y: 0,
    },
  });

  const handleDrag = (e, ui) => {
    const { x, y } = windowPosition.deltaPosition;
    setWindowPosition({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      },
    });
  };

  function titleCase(string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  useEffect(() => {
    dispatch(minimiseWindow());
    dispatch(closeWindow());
    dispatch(focusWindow());
  }, [dispatch]);

  const variants = {
    fullscreen: {
      position: 'absolute',
      x: 0,
      y: 0,
      left: `${
        windowPosition.deltaPosition.x >= 0
          ? -windowPosition.deltaPosition.x
          : -windowPosition.deltaPosition.x
      }px`,
      top: `${
        windowPosition.deltaPosition.y >= 0
          ? -windowPosition.deltaPosition.y
          : -windowPosition.deltaPosition.y
      }px`,
      height: '100vh',
      width: '100%',
    },

    enter: {
      x: '-200vw',
      opacity: 1,
    },

    stop: { x: '0', opacity: 1, zIndex: 10 },

    exitLeft: {
      x: '-200vw',
      opacity: 1,
    },
  };

  const windowAnimation = () => {
    if (fullscreen && !minimised) {
      return 'fullscreen';
    } else if (minimised) {
      return 'minimised';
    }
    return 'stop';
  };

  const handleMinimise = () => {
    dispatch(minimiseWindow(props.title));
    setWindowPosition({
      deltaPosition: {
        x: 0,
        y: 0,
      },
    });
    setFullscreen(false);
  };
  const handleClose = () => {
    dispatch(closeWindow(props.title));
    setFullscreen(false);
  };

  const onStart = (e) => {
    dispatch(focusWindow(props.title));
  };

  return (
    <AnimatePresence>
      {showHideValue.includes(props.title) === true && (
        <Draggable
          cancel=".CANCEL_DRAGGABLE"
          disabled={fullscreen ? true : false}
          onDrag={handleDrag}
          onStart={(e) => onStart(e)}
        >
          <StyledDraggable
            className="onlyFocus"
            zIndex={props.title === focusedWindow ? 5 : 1}
          >
            <StyledWindow
              width={props.width}
              height={props.height}
              key={props.title}
              className="window"
              variants={variants}
              transition={{ type: 'tween' }}
              initial="enter"
              animate={windowAnimation}
              exit="exitLeft"
            >
              <StyledTitleBar className="title-bar">
                <div className="title-bar-text">
                  {props.title ? titleCase(props.title) : 'ERR502: Untitled'}
                </div>
                <div className="title-bar-controls">
                  <button
                    className="CANCEL_DRAGGABLE"
                    onClick={() => handleMinimise()}
                    aria-label="Minimize"
                  />
                  <button
                    className="CANCEL_DRAGGABLE"
                    onClick={() => setFullscreen(!fullscreen)}
                    aria-label="Maximize"
                  />
                  <button
                    className="CANCEL_DRAGGABLE"
                    onClick={() => handleClose()}
                    aria-label="Close"
                  />
                </div>
              </StyledTitleBar>
              <StyledWindowBody
                className="window-body tree-view"
                bgColor={props.bgColor}
              >
                <StyledWindowContent>{props.children}</StyledWindowContent>
              </StyledWindowBody>
            </StyledWindow>
          </StyledDraggable>
        </Draggable>
      )}
    </AnimatePresence>
  );
};
