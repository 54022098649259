import { createSlice, createAction } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import Client from 'shopify-buy';

// Creates the client with Shopify-Buy and store info
//
// const client = Client.buildClient({
// 	storefrontAccessToken: "your-storefront-access-token",
// 	domain: "your-shop-name.myshopify.com",
// })

//
// Example Storefront
//

//vital-studios-llc.myshopify.com
//storefrontAccessToken: `${process.env.REACT_APP_TESTING_API_KEY}`,
//henseleit.myshopify.com
const client = Client.buildClient({
  storefrontAccessToken: 'c3684cf042f062027bd05c509ce1e1c3',
  domain: 'vital-studios-llc.myshopify.com',
});

const initialState = {
  isCartOpen: false,
  cartCount: 0,
  checkout: {},
  products: [],
  featured: [],
  product: {},
  shop: {},
};

// export const shopify = createSlice({
//   name: "shopify",
//   initialState: initialState,
//   reducers: {
//     productsFound: (state, action) => (state.products = action.payload),
//     productFound: (state, action) => (state.product = action.payload),
//     collectionFound: (state, action) => (state.featured = action.payload),
//     checkoutFound: (state, action) => (state.checkout = action.payload),
//     shopFound: (state, action) => (state.shop = action.payload),
//     addVariantToCart: (state, action) => (state.checkout = action.payload),
//     updateQuantityInCart: (state, action) => (state.checkout = action.payload),
//     removeLineItemInCart: (state, action) => (state.checkout = action.payload),
//     openCart: (state) => (state.isCartOpen = true),
//     closeCart: (state) => (state.isCartOpen = false),
//     cartCount: (state, action) => (state.cartCount = action.payload),
//   },
// });

const PRODUCTS_FOUND = createAction('shopify/PRODUCTS_FOUND');
const PRODUCT_FOUND = createAction('shopify/PRODUCT_FOUND');
const COLLECTION_FOUND = createAction('shopify/COLLECTION_FOUND');
const CHECKOUT_FOUND = createAction('shopify/CHECKOUT_FOUND');
const SHOP_FOUND = createAction('shopify/SHOP_FOUND');
const ADD_VARIANT_TO_CART = createAction('shopify/ADD_VARIANT_TO_CART');
const UPDATE_QUANTITY_IN_CART = createAction('shopify/UPDATE_QUANTITY_IN_CART');
const REMOVE_LINE_ITEM_IN_CART = createAction(
  'shopify/REMOVE_LINE_ITEM_IN_CART'
);
const OPEN_CART = createAction('shopify/OPEN_CART');
const CLOSE_CART = createAction('shopify/CLOSE_CART');
const CART_COUNT = createAction('shopify/CART_COUNT');

export const shopify = createSlice({
  name: 'shopify',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PRODUCTS_FOUND, (state, action) => {
        state.products = action.payload;
      })
      .addCase(PRODUCT_FOUND, (state, action) => {
        state.product = action.payload;
      })
      .addCase(COLLECTION_FOUND, (state, action) => {
        state.featured = action.payload;
      })
      .addCase(CHECKOUT_FOUND, (state, action) => {
        state.checkout = action.payload;
      })
      .addCase(SHOP_FOUND, (state, action) => {
        state.shop = action.payload;
      })
      .addCase(ADD_VARIANT_TO_CART, (state, action) => {
        state.checkout = action.payload;
      })
      .addCase(UPDATE_QUANTITY_IN_CART, (state, action) => {
        state.checkout = action.payload;
      })
      .addCase(REMOVE_LINE_ITEM_IN_CART, (state, action) => {
        state.checkout = action.payload;
      })
      .addCase(OPEN_CART, (state) => {
        state.isCartOpen = true;
      })
      .addCase(CLOSE_CART, (state) => {
        state.isCartOpen = false;
      })
      .addCase(CART_COUNT, (state, action) => {
        state.cartCount = action.payload;
      });
  },
});

export const shopifyReducer = shopify.reducer;

// Gets all the products from Shopify
function getProducts() {
  return (dispatch) => {
    client.product.fetchAll().then((resp) => {
      dispatch(PRODUCTS_FOUND(resp));
    });
  };
}

// Gets individual item based on id
function getProduct(id) {
  return async (dispatch) => {
    const resp = await client.product.fetch(id);
    dispatch(PRODUCT_FOUND(resp));
    return resp;
  };
}

//
// Gets a  collection based on that collection's id
//
// function getCollection() {
// 	const collectionId = "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzIwODAyMDYwMzAzMw=="
// 	return (dispatch) => {
// 		client.collection.fetchWithProducts(collectionId).then((resp) => {
// 			dispatch(COLLECTION_FOUND(resp.products))
// 		})
// 	}
// }

// Creates initial checkout state from Shopify
function checkout() {
  return (dispatch) => {
    client.checkout.create().then((resp) => {
      dispatch(CHECKOUT_FOUND(resp));
    });
  };
}

// Gets Shopify store information
function shopInfo() {
  return (dispatch) => {
    client.shop.fetchInfo().then((resp) => {
      dispatch(SHOP_FOUND(resp));
    });
  };
}

// Adds variants to cart/checkout
function addVariantToCart(checkoutId, lineItemsToAdd) {
  return async (dispatch) => {
    const response = await client.checkout.addLineItems(
      checkoutId,
      lineItemsToAdd
    );
    dispatch(ADD_VARIANT_TO_CART(response));
    return response;
  };
}

// Updates quantity of line items in cart and in checkout state
function updateQuantityInCart(lineItemId, quantity, checkoutId) {
  const lineItemsToUpdate = [
    { id: lineItemId, quantity: parseInt(quantity, 10) },
  ];

  return async (dispatch) => {
    const resp = await client.checkout.updateLineItems(
      checkoutId,
      lineItemsToUpdate
    );
    dispatch(UPDATE_QUANTITY_IN_CART(resp));
    return resp;
  };
}

// Removes line item from cart and checkout state
function removeLineItemInCart(checkoutId, lineItemId) {
  return (dispatch) => {
    client.checkout.removeLineItems(checkoutId, [lineItemId]).then((resp) => {
      dispatch(REMOVE_LINE_ITEM_IN_CART(resp));
    });
  };
}

// To close the cart
function handleCartClose() {
  return CLOSE_CART();
}

// To open the cart
function handleCartOpen() {
  return OPEN_CART();
}

// Set the count of items in the cart
function handleSetCount(count) {
  return CART_COUNT(count);
}

export function useShopify() {
  const dispatch = useDispatch();

  const cartStatus = useSelector(
    (appState) => appState.shopifyState.isCartOpen
  );
  const cartCount = useSelector((appState) => appState.shopifyState.cartCount);
  const products = useSelector((appState) => appState.shopifyState.products);
  const product = useSelector((appState) => appState.shopifyState.product);
  const featured = useSelector((appState) => appState.shopifyState.featured);
  const checkoutState = useSelector(
    (appState) => appState.shopifyState.checkout
  );
  const shopDetails = useSelector((appState) => appState.shopifyState.shop);
  const fetchProducts = () => dispatch(getProducts());
  const fetchProduct = (id) => dispatch(getProduct(id));
  // const fetchCollection = () => dispatch(getCollection())
  const createCheckout = () => dispatch(checkout());
  const createShop = () => dispatch(shopInfo());
  const closeCart = () => dispatch(handleCartClose());
  const openCart = () => dispatch(handleCartOpen());
  const setCount = (count) => dispatch(handleSetCount(count));

  const addVariant = (checkoutId, lineItemsToAdd) =>
    dispatch(addVariantToCart(checkoutId, lineItemsToAdd));
  const updateQuantity = (lineItemId, quantity, checkoutID) =>
    dispatch(updateQuantityInCart(lineItemId, quantity, checkoutID));
  const removeLineItem = (checkoutId, lineItemId) =>
    dispatch(removeLineItemInCart(checkoutId, lineItemId));

  return {
    products,
    product,
    featured,
    cartStatus,
    checkoutState,
    cartCount,
    shopDetails,
    addVariant,
    fetchProducts,
    fetchProduct,
    // fetchCollection,
    createCheckout,
    createShop,
    closeCart,
    openCart,
    updateQuantity,
    removeLineItem,
    setCount,
  };
}
