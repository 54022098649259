import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useShopify } from '../hooks';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { ClickToEnter } from './ClickToEnter';
import { Desktop } from './desktopComponents';

const StyledAppContainer = styled.div`
  position: fixed;
  top: ${window.visualViewport.offsetTop};
  height: ${window.visualViewport.height};
  width: 100vw;
`;

export const App = () => {
  const { createShop, createCheckout, fetchProducts } = useShopify();
  const location = useLocation();

  useEffect(() => {
    createShop();
    fetchProducts();
    createCheckout();
  }, []);

  return (
    <StyledAppContainer className="crt" id="appContainer">
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/Desktop">
            <Desktop />
          </Route>
          <Route exact path="/">
            <ClickToEnter />
          </Route>
        </Switch>
      </AnimatePresence>
    </StyledAppContainer>
  );
};
