import React from 'react';
import styled from 'styled-components';
import { ICON_FONT_SIZE } from '../../../helpers/defaultSizes';
import { useShopify } from '../../../hooks';

const LineItemContainer = styled.div`
  display: flex;
`;

const LineItemImg = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e5e5e5;
  @media only screen and (min-width: 100px) and (max-width: 1200px) {
    width: 40px;
    height: 40px;
  }
`;

const LineItemContent = styled.div`
  width: 100%;
  padding-left: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (min-width: 100px) and (max-width: 1200px) {
    padding-left: 15px;
  }
`;

const LineItemTitle = styled.div`
  font-size: ${ICON_FONT_SIZE};
  font-weight: bold;
`;

const LineItemVariant = styled.div`
  font-size: ${ICON_FONT_SIZE};
  font-weight: bold;
`;

const LineItemPrice = styled.div`
  font-size: ${ICON_FONT_SIZE};
  font-weight: bold;
`;

const LineItemQuantity = styled.div`
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: ${ICON_FONT_SIZE};
  border: none;
  text-align: center;
  background: transparent;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
`;

export const LineItem = () => {
  const { checkoutState, updateQuantity } = useShopify();

  function decrementQuantity(lineItemId, lineItemQuantity, e) {
    const checkoutId = checkoutState.id;
    const updatedQuantity = lineItemQuantity - 1;
    updateQuantity(lineItemId, updatedQuantity, checkoutId);
  }

  function incrementQuantity(lineItemId, lineItemQuantity, e) {
    const checkoutId = checkoutState.id;
    const updatedQuantity = lineItemQuantity + 1;
    updateQuantity(lineItemId, updatedQuantity, checkoutId);
  }

  // function deleteLineItem(lineItemId, e) {
  //   e.preventDefault();
  //   const checkoutId = checkoutState.id;
  //   removeLineItem(checkoutId, lineItemId);
  // }

  return (
    <div>
      {checkoutState.lineItems &&
        checkoutState.lineItems.map((lineItem, i) => {
          return (
            <LineItemContainer key={`${lineItem.title}` + i}>
              <LineItemImg>
                {lineItem.variant.image ? (
                  <img
                    src={lineItem.variant.image.src}
                    alt={`${lineItem.title} product shot`}
                  />
                ) : null}
              </LineItemImg>
              <LineItemContent>
                <LineItemTitle>{lineItem.title}</LineItemTitle>
                <LineItemVariant>
                  {lineItem.variant.title.toUpperCase()}
                </LineItemVariant>
                <div className="title-bar-controls">
                  <button
                    className="CANCEL_DRAGGABLE"
                    onClick={(e) =>
                      decrementQuantity(lineItem.id, lineItem.quantity, e)
                    }
                  >
                    -
                  </button>
                  <LineItemQuantity>{lineItem.quantity}</LineItemQuantity>
                  <button
                    className="CANCEL_DRAGGABLE"
                    onClick={(e) => {
                      incrementQuantity(lineItem.id, lineItem.quantity, e);
                    }}
                  >
                    +
                  </button>
                  {/* <button onClick={(e) => deleteLineItem(lineItem.id, e)}>
                    x
                  </button> */}
                </div>
                <LineItemPrice>
                  $ {(lineItem.quantity * lineItem.variant.price).toFixed(2)}
                </LineItemPrice>
              </LineItemContent>
            </LineItemContainer>
          );
        })}
    </div>
  );
};
