import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Shop } from '../desktopPages/Shop/Shop';
import { Contact } from '../desktopPages/Contact';
import { StartMenu } from './StartMenu';
import { Terms } from '../desktopPages/Terms';
import { Cart } from '../desktopPages/Shop/Cart';
import { Icons } from './Icon';
import { ProductView } from '../desktopPages/Shop/ProductView';
import styled from 'styled-components';
import { motion } from 'framer-motion/dist/framer-motion';
import { Game } from '../desktopPages/Game';
import { DesktopVideos } from '../../assets/images';
import { UnknownPage } from '../desktopPages/404';

const StyledDesktopContainer = styled(motion.div)`
  width: 100vw;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  background-color: #534a69;
  padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px)
    env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px);
`;

const StyledVideo = styled.video`
  object-fit: fill;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -10;

  #BGVideoMobile {
    display: none;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
  }
`;

const variants = {
  turnOn: {
    animation: 'turn-on 4s linear',
    animationFillMode: 'forwards',
    height: '100vh',
    width: '100%',
  },

  turnOff: {
    animation: 'turn-off 0.55s cubic-bezier(0.23, 1, 0.32, 1)',
    animationFillMode: 'forwards',
    height: '100vh',
    width: '100%',
  },
};

export const Desktop = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
  return (
    <StyledDesktopContainer variants={variants} animate="turnOn" exit="turnOff">
      <Icons />
      <Shop />
      <Cart />
      <Terms />
      <Game />
      <UnknownPage />
      <Contact />
      <ProductView />
      <StartMenu />
      {isMobile ? (
        <StyledVideo
          loop
          muted
          autoPlay
          playsInline
          src={DesktopVideos.DesktopMobileBG}
        />
      ) : (
        <StyledVideo
          loop
          muted
          autoPlay
          playsInline
          src={DesktopVideos.DesktopBG}
        />
      )}
    </StyledDesktopContainer>
  );
};
