import React, { useEffect } from 'react';
import { useShopify } from '../../../hooks';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { focusWindow, iconOpenWindow } from '../../../redux/ducks/windows';
// import { ICON_FONT_SIZE } from "../../../helpers/defaultSizes";

const ProductContainer = styled.div`
  margin: 40px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ProductIndividual = styled.div`
  flex: 0 0 31%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
  padding-bottom: 30px;
  &:hover {
    cursor: pointer;
  }
`;

const ProductThumbnailImage = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
`;
const ProductTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const ProductPrice = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: center;
`;

export const Products = () => {
  const { products } = useShopify();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(iconOpenWindow());
    dispatch(focusWindow());
  }, [dispatch]);

  const handleClick = (title) => {
    dispatch(iconOpenWindow(title));
    dispatch(focusWindow(title));
  };

  //TODO: Add sold out sign for sold out product

  return (
    <ProductContainer className="CANCEL_DRAGGABLE">
      {products &&
        products.map((product, i) => {
          const image = product.images[0];
          return product.title === 'Make active to close store' ? null : (
            <ProductIndividual
              className="CANCEL_DRAGGABLE"
              onClick={() => handleClick(product.title)}
              key={product.id + i}
              title={product.title}
              productID={product.id}
            >
              {image ? (
                <ProductThumbnailImage
                  src={image.src}
                  alt={`${product.title} product shot`}
                  draggable="false"
                />
              ) : null}
              <div>
                <ProductTitle>{product.title}</ProductTitle>
                <ProductPrice>$ {product.variants[0].price}</ProductPrice>
              </div>
            </ProductIndividual>
          );
        })}
    </ProductContainer>
  );
};
