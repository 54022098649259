import { createSlice } from '@reduxjs/toolkit';

const showHide = createSlice({
  name: 'showHideSlice',
  initialState: {
    window: ['shop'],
    focused: '',
    startMenuWindows: ['shop'],
    minimise: false,
  },
  reducers: {
    iconOpenWindow: (state, action) => {
      if (state.window.includes(action.payload) === false) {
        state.window = [...state.window, action.payload];
      }
      if (state.startMenuWindows.includes(action.payload) === false) {
        state.startMenuWindows = [...state.startMenuWindows, action.payload];
      }
      state.window = [...state.window];
      state.startMenuWindows = [...state.startMenuWindows];
      state.minimise = false;
    },
    closeWindow: (state, action) => {
      //closes window
      if (state.window.includes(action.payload) === true) {
        const divwindows = state.window.indexOf(action.payload);
        const newArr = state.window.splice(divwindows, 1);
        state.window = [...state.window, newArr];
      }

      //removes window from start menu
      if (state.startMenuWindows.includes(action.payload) === true) {
        const divstartMenuWindows = state.startMenuWindows.indexOf(
          action.payload
        );
        const newArr = state.startMenuWindows.splice(divstartMenuWindows, 1);
        state.startMenuWindows = [...state.startMenuWindows, newArr];
      }
    },
    focusWindow: (state, action) => {
      state.focused = action.payload;
    },
    minimiseWindow: (state, action) => {
      if (state.window.includes(action.payload) === true) {
        const divwindows = state.window.indexOf(action.payload);
        const newArr = state.window.splice(divwindows, 1);
        state.window = [...state.window, newArr];
      }
      state.window = [...state.window];

      if (state.startMenuWindows.includes(action.payload) === false) {
        state.startMenuWindows = [...state.startMenuWindows, action.payload];
      }
      state.startMenuWindows = [...state.startMenuWindows];
      state.minimise = true;
    },
  },
});

const startMenu = createSlice({
  name: 'startMenuSlice',
  initialState: {
    value: false,
  },
  reducers: {
    openStartMenu: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {
  iconOpenWindow,
  closeWindow,
  focusWindow,
  minimiseWindow,
  openMinimisedWindow,
  fullscreenWindow,
} = showHide.actions;
export const showHideReducer = showHide.reducer;

export const { openStartMenu } = startMenu.actions;
export const startMenuReducer = startMenu.reducer;
