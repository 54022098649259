import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useShopify } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { focusWindow, iconOpenWindow } from '../../../redux/ducks/windows';
import { ICON_FONT_SIZE } from '../../../helpers/defaultSizes';
import styled from 'styled-components';
import { Window } from '../../desktopComponents/Window';

const ProductViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px;
  @media only screen and (min-width: 100px) and (max-width: 1200px) {
    display: flex;
    padding: 50px 50px 180px 50px;
    flex-direction: column;
  }
`;

const SlideshowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 0 1 60%;
  align-items: center;
  @media only screen and (min-width: 100px) and (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

const MainImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const MainProductImage = styled.img`
  max-height: 800px;
`;

// const ImageSlideshowButton = styled.div`
//   width: 30px;
//   height: 30px;
//   border: 1px solid black;
// `;

const ProductImagesThumbnails = styled.div`
  display: flex;
  flex-direction: row;
  &:hover {
    cursor: pointer;
  }
`;

const ProductDescriptionContainer = styled.div`
  flex: 0 1 30%;
  @media only screen and (min-width: 100px) and (max-width: 1200px) {
  }
`;

const ProductDescriptionBody = styled.ul`
  margin: 0px !important;
  height: calc(100% - 20px);
  display: flex;
  justify-content: center;
  font-size: ${ICON_FONT_SIZE};
  h2 {
    font-weight: bold;
  }
  div {
    font-size: ${ICON_FONT_SIZE};
  }
  ul {
    font-size: ${ICON_FONT_SIZE};
  }
  button {
    margin-top: 40px;
    width: 100%;
  }
`;

const ProductDescriptionContent = styled.div`
  margin: 0px;
  height: 100%;
  width: 100%;
  button:hover {
    cursor: pointer;
  }
`;

const ProductVariantSelectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProductQuantitySelectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLabel = styled.label`
  font-size: ${ICON_FONT_SIZE};
`;
const StyledSelect = styled.select`
  width: 100px;
`;
const StyledInput = styled.input`
  width: 100px;
`;

export const ProductView = () => {
  const { products, fetchProducts, openCart, checkoutState, addVariant } =
    useShopify();
  const [size, setSize] = useState('');
  const [color, setColor] = useState('');
  const [quantity, setQuantity] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1]); //Match the number of products to this as individual product goes off of index and updates accordingly
  const [mainImage, setMainImage] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });

  function changeSize(sizeId, quantity, product) {
    const defaultSize = product.variants && product.variants[0].id.toString();

    const defaultColor =
      product.options[1] && product.options[1].values[0].value;
    openCart();
    if (sizeId === '') {
      sizeId = defaultSize;
      const lineItemsToAdd = [
        { variantId: sizeId, quantity: parseInt(quantity, 10) },
      ];
      const checkoutId = checkoutState.id;
      addVariant(checkoutId, lineItemsToAdd);
    } else {
      const lineItemsToAdd = [
        { variantId: sizeId, quantity: parseInt(quantity, 10) },
      ];
      const checkoutId = checkoutState.id;
      addVariant(checkoutId, lineItemsToAdd);
    }
  }

  const dispatch = useDispatch();

  useEffect(() => {
    fetchProducts();
    dispatch(iconOpenWindow());
    dispatch(focusWindow());
  }, [dispatch]);

  const onAddToCart = (size, quantity, product) => {
    changeSize(size, quantity, product);
    dispatch(iconOpenWindow('cart'));
    dispatch(focusWindow('cart'));
  };

  const handleQuantity = (e, i) => {
    const value = e.target.value;
    const newQuantities = [...quantity];
    newQuantities[i] = value;
    console.log(newQuantities);
    setQuantity(() => newQuantities);
  };

  const handleImages = (i, index) => {
    const newImages = [...mainImage];
    newImages[i] = index;
    setMainImage(() => newImages);
  };

  //TODO: Disable add to cart for sold out products

  return (
    <>
      {products &&
        products.map((product, i) => {
          return (
            <Window title={product.title} key={i} height={'500px'}>
              <ProductViewContainer className="CANCEL_DRAGGABLE">
                <SlideshowContainer>
                  {product.images && (
                    <MainImageContainer>
                      <MainProductImage
                        key={product.images[mainImage[i]].id}
                        src={product.images[mainImage[i]].src}
                        alt={`${product.title} product shot`}
                        draggable="false"
                      />
                    </MainImageContainer>
                  )}
                  <ProductImagesThumbnails>
                    {product.images &&
                      product.images.map((image, index) => {
                        return (
                          <img
                            className="CANCEL_DRAGGABLE"
                            key={image.id + i}
                            src={image.src}
                            alt={`${product.title} product shot`}
                            onClick={() => handleImages(i, index)}
                            style={{ height: '100px', width: '100px' }}
                            draggable="false"
                          />
                        );
                      })}
                  </ProductImagesThumbnails>
                </SlideshowContainer>
                <ProductDescriptionContainer className="window">
                  {!isMobile && (
                    <div className="title-bar">
                      <div className="title-bar-text">{product.title}</div>
                    </div>
                  )}
                  <ProductDescriptionBody className="window-body tree-view">
                    <ProductDescriptionContent>
                      <ProductVariantSelectionContainer>
                        <StyledLabel htmlFor={'prodOptions'}>Size</StyledLabel>
                        <StyledSelect
                          className="CANCEL_DRAGGABLE"
                          id="prodOptions"
                          name={size}
                          onChange={(e) => {
                            setSize(e.target.value);
                          }}
                        >
                          {product.variants &&
                            product.variants.map((item, i) => {
                              return (
                                <option
                                  value={item.id.toString()}
                                  key={item.title + i}
                                >{`${item.title}`}</option>
                              );
                            })}
                        </StyledSelect>
                      </ProductVariantSelectionContainer>
                      {/* {product.options[1] && (
                        <ProductVariantSelectionContainer>
                          <StyledLabel htmlFor={'prodOptions'}>
                            Color
                          </StyledLabel>
                          <StyledSelect
                            className="CANCEL_DRAGGABLE"
                            id="prodOptions"
                            name={color}
                            onChange={(e) => {
                              setColor(e.target.value);
                            }}
                          >
                            {console.log(product.options[1].values)}

                            {product.options[1].values.map((item, i) => {
                              return (
                                <option
                                  value={item.value.toString()}
                                  key={i}
                                >{`${item.value}`}</option>
                              );
                            })}
                          </StyledSelect>
                        </ProductVariantSelectionContainer>
                      )} */}
                      <ProductQuantitySelectionContainer>
                        <StyledLabel>Quantity</StyledLabel>
                        <StyledInput
                          className="CANCEL_DRAGGABLE"
                          type="number"
                          min={1}
                          value={quantity[i]}
                          onChange={(e) => handleQuantity(e, i)}
                        ></StyledInput>
                      </ProductQuantitySelectionContainer>
                      <h3>$ {product.variants && product.variants[0].price}</h3>
                      <div>Description</div>
                      <ul>
                        {product.description &&
                          product.description.split('@').map((each, i) => {
                            return (
                              <li key={`line-description +${i}`}>{each}</li>
                            );
                          })}
                      </ul>
                      <button
                        className="CANCEL_DRAGGABLE"
                        onClick={(e) => onAddToCart(size, quantity[i], product)}
                      >
                        Add to Cart
                      </button>
                    </ProductDescriptionContent>
                  </ProductDescriptionBody>
                </ProductDescriptionContainer>
              </ProductViewContainer>
            </Window>
          );
        })}
    </>
  );
};
