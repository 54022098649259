import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { App } from "./components/App";
import { BrowserRouter as Router } from "react-router-dom";
import "98.css";
// import "xp.css";
import "./app.css";

document
  .getElementsByTagName("body")[0]
  .addEventListener("touchstart", function () {});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
