import React, { useState } from 'react';
import styled from 'styled-components';
import { Window } from '../desktopComponents/Window';
import { ICON_FONT_SIZE } from '../../helpers/defaultSizes';
import emailjs from 'emailjs-com';
import { useDispatch } from 'react-redux';
import { focusWindow, iconOpenWindow } from '../../redux/ducks/windows';

const StyledLegend = styled.legend`
  font-size: ${ICON_FONT_SIZE};
  font-weight: bold;
`;

const StyledDiv = styled.div`
  margin-bottom: 10px;
`;

const FormTitles = styled.div`
  width: 100%;
  font-size: ${ICON_FONT_SIZE};
`;

const FormInput = styled.input`
  height: 24px !important;
  width: 100%;
  font-size: ${ICON_FONT_SIZE};
`;

const FormTextAreaTitle = styled.div`
  width: 100%;
  font-size: ${ICON_FONT_SIZE};
`;

const FormTextArea = styled.textarea`
  width: 100%;
  resize: vertical;
  font-size: ${ICON_FONT_SIZE};
`;

const StyledButton = styled.button`
  @media only screen and (min-width: 100px) and (max-width: 1200px) {
    width: 100%;
  }
`;

const EmailSuccessMessage = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  font-size: ${ICON_FONT_SIZE};
`;

export const Contact = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  //TODO: style alerts
  const submit = () => {
    if (!isValidEmail(email)) {
      alert('Please enter a valid email');
    } else if (name && email && message && subject) {
      const serviceId = 'service_cv82th4';
      const templateId = 'template_xd9co6u';
      const userId = 'lQ_GvwhPdSHbPvkIk';
      const templateParams = {
        name,
        subject,
        email,
        message,
      };

      emailjs
        .send(serviceId, templateId, templateParams, userId)
        .then((response) => console.log(response))
        .then((error) => console.log(error));

      setName('');
      setSubject('');
      setEmail('');
      setMessage('');
      setEmailSent(true);
      dispatch(iconOpenWindow('email success!'));
      dispatch(focusWindow('email success!'));
    } else {
      alert('Please fill in all fields.');
    }
  };

  const isValidEmail = (email) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  return (
    <>
      <Window title={'contact'} height={'auto'}>
        <fieldset
          style={{ width: '100%', height: '100%' }}
          className="CANCEL_DRAGGABLE"
        >
          <StyledLegend>Contact Form</StyledLegend>
          <StyledDiv>
            <FormTitles> Email Address: </FormTitles>
            <FormInput
              className="CANCEL_DRAGGABLE"
              type="email"
              name="email"
              required="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </StyledDiv>
          <StyledDiv>
            <FormTitles> Name: </FormTitles>
            <FormInput
              className="CANCEL_DRAGGABLE"
              type="text"
              name="name"
              onChange={(e) => setName(e.target.value)}
            />
          </StyledDiv>
          <StyledDiv>
            <FormTitles> Subject: </FormTitles>
            <FormInput
              className="CANCEL_DRAGGABLE"
              type="text"
              name="subject"
              onChange={(e) => setSubject(e.target.value)}
            />
          </StyledDiv>
          <StyledDiv>
            <FormTextAreaTitle> Message: </FormTextAreaTitle>
            <FormTextArea
              className="message CANCEL_DRAGGABLE"
              name="message"
              rows="6"
              cols="30"
              maxLength="500"
              onChange={(e) => setMessage(e.target.value)}
            />
          </StyledDiv>
          <div>
            <StyledButton className="CANCEL_DRAGGABLE" onClick={submit}>
              Send
            </StyledButton>
          </div>
        </fieldset>
      </Window>
      {emailSent ? (
        <Window
          title={'email success!'}
          width={'450px'}
          height={'100px'}
          bgColor={'white'}
        >
          <EmailSuccessMessage>
            Thank you for your message, we will be in touch in no time!
          </EmailSuccessMessage>
        </Window>
      ) : null}
    </>
  );
};
