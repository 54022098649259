import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import DesktopIcon from '../../assets/icons/DesktopIcon.png';
import {
  focusWindow,
  iconOpenWindow,
  openStartMenu,
} from '../../redux/ducks/windows';
import { IconsImageSrc } from './Icon';
import { titleCase } from '../../helpers';
import { ICON_FONT_SIZE } from '../../helpers/defaultSizes';
import { Link } from 'react-router-dom';

const StyledStartMenuContainer = styled.div`
  position: fixed;
  scroll: none;
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40px;
  z-index: 10;
`;

const StyledStartMenuButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  width: 200px;
  z-index: 5;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (min-width: 100px) and (max-width: 1200px) {
    width: 150px;
  }
`;

const StyledStartMenuButtonTabTitle = styled.div`
  color: black;
  font-weight: bold;
  font-size: ${ICON_FONT_SIZE};
`;

const StyledStartMenuWindowTabTitle = styled.div`
  font-size: ${ICON_FONT_SIZE};
`;

const StyledIconImage = styled.img`
  width: 30px;
  margin-right: 5px;
  @media only screen and (min-width: 100px) and (max-width: 1200px) {
    width: 30px;
  }
`;

const StartMenuTabsContainer = styled.div`
  white-space: nowrap;
  z-index: 3;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
  overflow-x: scroll; /* Add the ability to scroll y axis*/

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledStartMenuWindowButton = styled.button`
  margin-left: 5px;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (min-width: 100px) and (max-width: 1200px) {
    margin-left: 3px;
    margin-right: 3px;
  }
`;

const StyledStartMenuPopUpContainer = styled.div`
  position: absolute;
  bottom: 34px;
  width: 250px;
`;

const RotatedStartMenuText = styled.div`
  width: 348px;
  height: 20px;
  position: absolute;
  bottom: -15px;
  left: 2px;
  font-size: ${ICON_FONT_SIZE};
  transform-origin: 0 0;
  transform: rotate(270deg);
`;

const StyledStartMenuPopUpContent = styled.div`
  color: black;
`;

const StyledSMPopupTabContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  padding-left: 20px;
  &:hover {
    background: #ae00ff;
    cursor: pointer;
  }
`;

const StyledSMPopupImg = styled.img`
  width: 50px;
  heigh: 50px;
`;

const StyledSMPopupTitle = styled.div`
  color: black;
`;

const StyledStatusBar = styled.div`
  margin-left: auto;
  z-index: 5;
`;

const StyledStatusBarSpan = styled.span`
  color: black;
  font-size: ${ICON_FONT_SIZE};
`;

export const StartMenu = () => {
  const [time, setTime] = useState(new Date());
  const dispatch = useDispatch();

  const expanded = useSelector((state) => state.startMenuState.value);

  const startMenuWindowTabs = useSelector((state) =>
    state.showHideState.startMenuWindows.filter(function (element) {
      return typeof element === 'string';
    })
  );

  let startMenu = document.getElementById('startMenu');
  let viewport = window.visualViewport;
  function viewportHandler() {
    let appContainer = document.getElementById('appContainer');

    // Since the bar is position: fixed we need to offset it by the visual
    // viewport's offset from the layout viewport origin.
    let offsetLeft = viewport.offsetLeft;
    var offsetY = window.innerHeight - viewport.height - viewport.offsetTop;

    // You could also do this by setting style.left and style.top if you
    // use width: 100% instead.
    startMenu.style.left = offsetLeft + 'px,';
    startMenu.style.top = offsetY + 'px';
    startMenu.style.transform = 'scale(' + 1 / viewport.scale + ')';
  }
  window.visualViewport.addEventListener('scroll', viewportHandler);
  window.visualViewport.addEventListener('resize', viewportHandler);

  const handleTime = () => {
    setTime(new Date());
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      dispatch(iconOpenWindow());
      dispatch(focusWindow());

      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch(openStartMenu(false));
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    var timerID = setInterval(() => handleTime(), 60000);
    return () => {
      // Unbind the event listener on clean up
      clearInterval(timerID);
    };
  });

  const handleClick = (title) => {
    dispatch(iconOpenWindow(title));
    dispatch(focusWindow(title));
  };

  const startMenuRef = useRef(null);
  useOutsideAlerter(startMenuRef);

  return (
    <>
      {expanded ? (
        <StyledStartMenuPopUpContainer
          className="window"
          id="startMenu"
          ref={startMenuRef}
        >
          <RotatedStartMenuText className="title-bar">
            <div className="title-bar-text">Vital Studios</div>
          </RotatedStartMenuText>
          <StyledStartMenuPopUpContent>
            {IconsImageSrc.map((props, index) => {
              return props.title === 'shutdown' ? (
                <Link
                  to="/"
                  style={{ textDecoration: 'none', color: 'black' }}
                  key={index}
                >
                  <StyledSMPopupTabContainer
                    onClick={() => handleClick(props.title)}
                  >
                    <StyledSMPopupImg src={props.imgSrc} draggable="false" />
                    <StyledSMPopupTitle>
                      {titleCase(props.title)}
                    </StyledSMPopupTitle>
                  </StyledSMPopupTabContainer>
                </Link>
              ) : (
                <StyledSMPopupTabContainer
                  onClick={() => handleClick(props.title)}
                  key={index}
                >
                  <StyledSMPopupImg src={props.imgSrc} draggable="false" />
                  <StyledSMPopupTitle>
                    {titleCase(props.title)}
                  </StyledSMPopupTitle>
                </StyledSMPopupTabContainer>
              );
            })}
          </StyledStartMenuPopUpContent>
        </StyledStartMenuPopUpContainer>
      ) : null}
      <StyledStartMenuContainer className="window">
        <StyledStartMenuButton onClick={() => dispatch(openStartMenu(true))}>
          <StyledIconImage src={DesktopIcon} draggable="false" />
          <StyledStartMenuButtonTabTitle>
            Vital Studios
          </StyledStartMenuButtonTabTitle>
        </StyledStartMenuButton>
        <StartMenuTabsContainer>
          {startMenuWindowTabs.map((title, index) => (
            <StyledStartMenuWindowButton
              key={index}
              onClick={() => handleClick(title)}
            >
              <StyledStartMenuWindowTabTitle>
                {title}
              </StyledStartMenuWindowTabTitle>
            </StyledStartMenuWindowButton>
          ))}
        </StartMenuTabsContainer>
        <StyledStatusBar className="status-bar">
          <StyledStatusBarSpan className="status-bar-field">
            <div>
              {time.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
            <div>{time.toLocaleDateString()}</div>
          </StyledStatusBarSpan>
        </StyledStatusBar>
      </StyledStartMenuContainer>
    </>
  );
};
