import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { shopifyReducer } from './ducks/shopify';
import { showHideReducer, startMenuReducer } from './ducks/windows';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  shopifyState: shopifyReducer,
  showHideState: showHideReducer,
  startMenuState: startMenuReducer,
});
const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, enhancer);

export default store;
