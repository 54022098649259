import ShopIcon from "./icons/ShopIcon.png";
import CartIcon from "./icons/CartIcon.png";
import GameIcon from "./icons/GameIcon.png";
import TermsIcon from "./icons/TermsIcon.png";
import ShutdownIcon from "./icons/ShutdownIcon.png";
import BookIcon from "./icons/BookIcon.png";
import ContactIcon from "./icons/ContactIcon.png";
import DesktopBG from "./videos/DesktopBG.mp4";
import DesktopMobileBG from "./videos/DesktopMobileBG.mp4";
import EnterBG from "./videos/EnterBG.mp4";
import EnterMobileBG from "./videos/EnterMobileBG.mp4";

export const IconImages = {
  ShopIcon,
  CartIcon,
  GameIcon,
  TermsIcon,
  ShutdownIcon,
  BookIcon,
  ContactIcon,
};

export const DesktopVideos = {
  DesktopBG,
  DesktopMobileBG,
};

export const EnterVideos = {
  EnterBG,
  EnterMobileBG,
};
