import React, { useEffect } from 'react';
import { IconImages } from '../../assets/images';
import { useMediaQuery } from 'react-responsive';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { titleCase } from '../../helpers';
import { focusWindow, iconOpenWindow } from '../../redux/ducks/windows';

export const IconsImageSrc = [
  {
    title: 'shop',
    imgSrc: IconImages.ShopIcon,
  },
  {
    title: 'cart',
    imgSrc: IconImages.CartIcon,
  },
  {
    title: 'terms',
    imgSrc: IconImages.TermsIcon,
  },
  {
    title: 'game',
    imgSrc: IconImages.GameIcon,
  },
  {
    title: 'contact',
    imgSrc: IconImages.ContactIcon,
  },
  {
    title: '404.exe',
    imgSrc: IconImages.ShutdownIcon,
  },
  {
    title: 'shutdown',
    imgSrc: IconImages.ShutdownIcon,
  },
];

const StyledIconContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  cursor: pointer;

  &:focus {
    outline: 1px dotted #000000;
    outline-offset: -4px;
  }
`;

const StyledIconTitle = styled.div`
  color: white;
  letter-spacing: 0px !important;
`;

const StyledIconImage = styled.img`
  width: 50px;
  heigh: 50px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
`;

const AllIconsContainer = styled.div`
  position: absolute;
`;

export const Icon = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: `(max-width: 700px)` });

  useEffect(() => {
    dispatch(iconOpenWindow());
    dispatch(focusWindow());
  }, [dispatch]);

  const handleClick = () => {
    dispatch(iconOpenWindow(props.title));
    dispatch(focusWindow(props.title));
  };

  return isMobile ? (
    <StyledIconContainer
      onDoubleClick={() => handleClick()}
      onTouchStart={() => handleClick()}
    >
      <StyledIconImage src={props.imgSrc} draggable="false" />
      <StyledIconTitle>{titleCase(props.title)}</StyledIconTitle>
    </StyledIconContainer>
  ) : (
    <Draggable>
      <StyledIconContainer
        onDoubleClick={() => handleClick()}
        onTouchStart={() => handleClick()}
      >
        <StyledIconImage src={props.imgSrc} draggable="false" />
        <StyledIconTitle>{titleCase(props.title)}</StyledIconTitle>
      </StyledIconContainer>
    </Draggable>
  );
};

export const Icons = () => {
  return (
    <AllIconsContainer>
      {IconsImageSrc.map((props, index) => {
        if (props.title === 'shutdown') {
          return null;
        }
        return <Icon key={index} title={props.title} imgSrc={props.imgSrc} />;
      })}
    </AllIconsContainer>
  );
};
