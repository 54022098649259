import React from 'react';
import styled from 'styled-components';
import { Window } from '../desktopComponents/Window';
import { ICON_FONT_SIZE } from '../../helpers/defaultSizes';

const TermsTitle = styled.li`
  font-weight: bold;
  font-size: ${ICON_FONT_SIZE};
`;

const TermsSectionContent = styled.li`
  font-size: ${ICON_FONT_SIZE};
  font-style: ${(props) => (props.textStyle ? props.textStyle : 'none')};
`;

export const Terms = () => {
  return (
    <Window title={'terms'} bgColor={'white'} height={'auto'}>
      <ul>
        <TermsTitle>TERMS & CONDITIONS</TermsTitle>
        <ul>
          <TermsSectionContent textStyle={'italic'}>
            Stated below are the terms and conditions for Vital Studios. Please
            be aware that your use of our site and the purchase of our products
            bounds you to these terms.
          </TermsSectionContent>
        </ul>
        <br />
        <TermsTitle>RETURNS, CANCELLATIONS, REFUNDS AND EXCHANGES</TermsTitle>
        <ul>
          <TermsSectionContent>
            Vital Studios reserves the right to request photographic evidence
            regarding defected products before authorising return.
          </TermsSectionContent>
          <TermsSectionContent>
            Vital Studios reserves the right to refuse unauthorised returns.
          </TermsSectionContent>
          <TermsSectionContent>
            Vital Studios will not accept any worn items.
          </TermsSectionContent>
          <TermsSectionContent>
            Upon inspection of your return we will process the exchange and you
            will be sent a confirmation email. We suggest you use a tracked
            postage method as Vital Studios will not be responsible for return
            packages being lost or damaged. We are not responsible for return
            shipping costs or duty fees. Customers bear the risks that the
            product may endure damage once dispatched by Vital Studios. Once the
            product is dispatched by our trusted carrier we absolve ourselves of
            all responsibility towards loss or damage of your order.
          </TermsSectionContent>
        </ul>
        <br />
        <TermsTitle>PRIVACY POLICY</TermsTitle>
        <ul>
          <TermsSectionContent>
            Under no circumstances will your personal information be shared or
            sold to third parties.
          </TermsSectionContent>
        </ul>
        <br />
        <br />
        <TermsSectionContent textStyle={'italic'}>
          Vital Studios
        </TermsSectionContent>
      </ul>
    </Window>
  );
};
