import React from 'react';
import { Window } from '../../desktopComponents/Window';
import { Products } from './Products';
import { useShopify } from '../../../hooks';
import { PasswordProtectedShop } from './PasswordProtectedShop';

export const Shop = () => {
  const { products, featured } = useShopify();

  const closeStore = () => {
    let productTitles = [];
    products.forEach((product, i) => {
      const title = product.title;
      productTitles.push(title);
    });
    if (productTitles.includes('Make active to close store')) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {closeStore() === true ? (
        <Window title="shop">
          <Products />
        </Window>
      ) : (
        <PasswordProtectedShop title="shop" />
      )}
    </>
  );
};
