import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Window } from '../../desktopComponents/Window';
import { Products } from './Products';
import { ICON_FONT_SIZE } from '../../../helpers/defaultSizes';
import { useDispatch } from 'react-redux';
import { focusWindow, iconOpenWindow } from '../../../redux/ducks/windows';

const SecretPasswordContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  font-size: ${ICON_FONT_SIZE};
  color: white;
`;

const GuessingText = styled.div`
  font-size: ${ICON_FONT_SIZE};
  margin-bottom: 20px;
`;

const EnterPassword = styled.label`
  font-size: ${ICON_FONT_SIZE};
  margin-right: 5px;
`;

const InputPassword = styled.input`
  font-size: ${ICON_FONT_SIZE};
  border: none;
  background: none;
  color: white;

  &:focus {
    outline: none;
  }
`;

export const PasswordProtectedShop = (props) => {
  const [secretCode, setSecretCode] = useState('');
  const [entryCounter, setEntryCounter] = useState(0);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [openSecretWindow, setOpenSecretWindow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(iconOpenWindow());
    dispatch(focusWindow());
  }, [dispatch]);

  const handleSubmit = (e) => {
    if (e.key === 'Enter' && secretCode !== 'JUNEVITAL' && secretCode !== '') {
      setEntryCounter(getRandomInt(9));
      setFirstSubmit(true);
      setSecretCode('');
    } else if (secretCode === 'JUNEVITAL') {
      setEntryCounter(9);
      setSecretCode('');
      setFirstSubmit(true);
      setOpenSecretWindow(true);
      dispatch(iconOpenWindow('Shop.secret.access'));
      dispatch(focusWindow('Shop.secret.access'));
    }
  };

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const failureTexts = [
    'guess again',
    'keep trying',
    'nope...',
    'cmon?',
    '...',
    'try again',
    'hint: its not that',
    'hi, from rowan =the dev= (⌐■ ͜ʖ■)',
    'ur bad',
    'wow you actually got it',
  ];

  return (
    <>
      <Window
        title={props.title}
        width={'450px'}
        height={'100px'}
        bgColor={'black'}
      >
        <SecretPasswordContainer>
          <GuessingText>
            {firstSubmit ? failureTexts[entryCounter] : 'Have a guess?'}
          </GuessingText>
          <div>
            <EnterPassword>Enter Password:</EnterPassword>
            <InputPassword
              className="CANCEL_DRAGGABLE"
              placeholder="Password"
              value={secretCode}
              onChange={(e) => setSecretCode(e.target.value)}
              onKeyDown={(e) => handleSubmit(e)}
            ></InputPassword>
          </div>
        </SecretPasswordContainer>
      </Window>
      {openSecretWindow ? (
        <Window title="Shop.secret.access" height={'500px'}>
          <Products />
        </Window>
      ) : null}
    </>
  );
};
