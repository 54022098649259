import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion/dist/framer-motion';
import { EnterVideos } from '../assets/images';

const StyledBackground = styled(motion.div)`
  height: 100%;
  width: 100%;
`;

const StyledVideo = styled.video`
  object-fit: fill;
  width: 100%;
  height: 100%;
  position: fixed;

  #BGVideoMobile {
    display: none;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
  }
`;

const variants = {
  turnOn: {
    animation: 'turn-on 4s linear',
    animationFillMode: 'forwards',
    height: '100vh',
    width: '100%',
  },

  turnOff: {
    animation: 'turn-off 0.55s cubic-bezier(0.23, 1, 0.32, 1)',
    animationFillMode: 'forwards',
    height: '100vh',
    width: '100%',
  },
};

export const ClickToEnter = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
  return (
    <Link to="/Desktop">
      <StyledBackground variants={variants} animate="turnOn" exit="turnOff">
        {isMobile ? (
          <StyledVideo
            loop
            muted
            autoPlay
            playsInline
            src={EnterVideos.EnterMobileBG}
          />
        ) : (
          <StyledVideo
            loop
            muted
            autoPlay
            playsInline
            src={EnterVideos.EnterBG}
          />
        )}
      </StyledBackground>
    </Link>
  );
};
