import React, { useEffect } from 'react';
import styled from 'styled-components';
import { LineItem } from './LineItem';
import { useShopify } from '../../../hooks';
import { Window } from '../../desktopComponents/Window';
import { ICON_FONT_SIZE } from '../../../helpers/defaultSizes';

const CartContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TotalPriceContainer = styled.div`
  font-size: ${ICON_FONT_SIZE};
`;

const TotalTitle = styled.h2`
  text-align: right;
`;

// const TotalPrice = styled.span`
// font
//   font-weight: bold;
//   font-size: ${ICON_FONT_SIZE};
// `;

const LineItemContainer = styled.ul`
  list-style: none;
`;

const CartCheckoutButton = styled.button`
  bottom: 0px;
  height: 30px;
  width: 100%;
`;

export const Cart = () => {
  const { checkoutState, setCount } = useShopify();

  function openCheckout(e) {
    e.preventDefault();
    // window.open(checkoutState.webUrl) // opens checkout in a new window
    window.location.replace(checkoutState.webUrl); // opens checkout in same window
  }

  useEffect(() => {
    function getCount() {
      let lineItems =
        checkoutState.lineItems && checkoutState.lineItems.length > 0
          ? checkoutState.lineItems
          : [];
      let count = 0;
      lineItems.forEach((item) => {
        count += item.quantity;
        return count;
      });

      setCount(count);
    }

    getCount();
  }, []);

  //TODO: Disable checkout for no items in cart

  return (
    <Window title={'cart'} height={'auto'}>
      <CartContainer className="CANCEL_DRAGGABLE">
        <HeaderContainer>
          <header>
            <h2>Your cart</h2>
          </header>
          <TotalPriceContainer>
            <TotalTitle>Total: $ {checkoutState.totalPrice}</TotalTitle>
          </TotalPriceContainer>
        </HeaderContainer>
        <LineItemContainer>
          <LineItem />
        </LineItemContainer>
        <CartCheckoutButton
          className="CANCEL_DRAGGABLE"
          onClick={(e) => openCheckout(e)}
        >
          Checkout
        </CartCheckoutButton>
      </CartContainer>
    </Window>
  );
};
